import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
export default function ChangePasswordNew({ internal }) {
  const ui = useSelector((state) => state.account.ui);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const [transactionPassword, setTransactionPassword] = React.useState("");
  const toast = useToast();
    const dispatch = useDispatch();

    const changePassword = async () => {
      if (password === "" || confirmPassword === "" || oldPassword === "") {
        toast({
          title: "Error",
          description: "Please fill all the fields",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password !== confirmPassword) {
        toast({
          title: "Error",
          description: "Password and confirm password does not match",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password === oldPassword) {
        toast({
          title: "Error",
          description: "New password and old password cannot be same",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password.length < 5) {
        toast({
          title: "Error",
          description: "Password must contain at least 5 characters",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      try {
        var { data, code, message, response, error } = await changePasswordAPI(
          {
            newPassword: Encrypt(password),
            password: Encrypt(oldPassword),
            resetRequired: false,
            uid: userData.id,
          },
          internal ? userData.token : localStorage.getItem("token")
        );
        if (code === 200) {
          toast({
            title: "Success",
            description: "Password changed successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          window.location.href = "/tp";
        } else {
          toast({
            title: "Error",
            description: "Wrong Password",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } catch (e) {
        console.log(e);
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    };
  useEffect(() => {
    toast({
      title: "Info",
      description: "Please change your password",
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          paddingTop: 0,
          padding: 10,
        }}
        className="log-fld login_container"
      >
        <h2 className="text-center">Change Password</h2>
        <div data-vv-scope="form-changepassword" className="change-form p-2">
          <div className="form-group">
            <label className="user-email-text">
              {internal ? "Old Password" : "Transaction Password"}
            </label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              name="OldPassword"
              className="form-control"
              aria-required="true"
              aria-invalid="true"
            />
          </div>
          <div className="form-group">
            <label className="user-email-text">New Password</label>
            <input
              type="password"
              value={password}
              name="NewPassword"
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              aria-required="false"
              aria-invalid="false"
            />
          </div>
          <div className="form-group">
            <label className="user-email-text">Confirm Password</label>
            <input
              type="password"
              name="ConfirmNewPassword"
              data-vv-as="NewPassword"
              value={confirmPassword}
              className="form-control"
              onChange={(e) => setConfirmPassword(e.target.value)}
              aria-required="true"
              aria-invalid="false"
            />
          </div>
          <div className="form-group mb-1">
            <button
              onClick={changePassword}
              type="submit"
              className="btn btn-submit btn-login"
            >
              Change Password
            </button>
          </div>
          <div className="form-group mb-0">
            <button
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
              type="submit"
              className="btn btn-submit btn-login"
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
