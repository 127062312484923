import {
  AddIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import store from "../redux/store";
import "../pages/Game/all.css";
import "../pages/Game/game.css";
import AsyncSelect from "react-select/async";
import {
  getUserDataAPI,
  getUserDetailsAPI,
  searchUserAPI,
} from "../service/AuthService";
export default function Header({ drawerButtonRef, onOpen }) {
  const [userName, setUserName] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const dispatch = useDispatch();
  const toast = useToast();

  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue)
      .then((response) => {
        return response.response.map((userName) => ({
          value: userName,
          label: userName,
        }));
      })
      .catch((error) => {
        dispatch({
          type: "accountData/logOut",
          payload: {},
        });
        window.localStorage.clear();
        window.location.href = "/";
      });
  };
  const getUserDetail = async (userName) => {
    const { response, message, code } = await getUserDetailsAPI(userName);
    if (code == 200) {
      setIsModalOpen(true);
      setUserDetails(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(
      store.getState().account.userData.id
    );
    dispatch({
      type: "accountData/setUserData",
      payload: response,
    });
  };
  useEffect(() => {
    setUserName(store.getState().account.userData.username);
    const interval = setInterval(() => {
      getAccountDetails();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <header>
      <div class="header-bottom">
        <div class="container-fluid">
          <a href="/admin/manage-client/list" class="logo">
            <img src={require("../assets/img/logo.png")} />
          </a>
          <div
            ref={drawerButtonRef}
            style={{
              position: "absolute",
              left: "135px",
              top: "15px",
              zIndex: 10,
            }}
            onClick={onOpen}
            class="side-menu-button"
          >
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>

          <Modal
            blockScrollOnMount={false}
            scrollBehavior="outside"
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modal Title</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div
                  className="modal fade show"
                  id="modal-user-detail"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-lg">
                    <div
                      style={{
                        overflowY: "auto",
                        height: "80vh",
                      }}
                      className="modal-content"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">User Detail</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          onClick={() => {
                            setIsModalOpen(false);
                          }}
                        >
                          ×
                        </button>
                      </div>
                      <div className="modal-body dark" id="search-user-details">
                        <div className="row">
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">Game Lock</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>EventName</th>
                                  <th>UserName</th>
                                  <th>UserActive</th>
                                  <th>BetActive</th>
                                  <th>FancyActive</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">User Lock</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>UserName</th>
                                  <th>AccountType</th>
                                  <th>UserActive</th>
                                  <th>BetActive</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.userLockUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      {" "}
                                      <td>{user.userName}</td>{" "}
                                      <td>{user.userType}</td>{" "}
                                      <td>
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            disabled
                                            defaultChecked="checked"
                                          />
                                          {user.userActive && (
                                            <span className="checkmark" />
                                          )}
                                        </label>
                                      </td>{" "}
                                      <td>
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            disabled
                                            defaultChecked="checked"
                                          />
                                          {user.betActive && (
                                            <span className="checkmark" />
                                          )}
                                        </label>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-12 m-t-10">
                            <h4 className="m-b-10 col-md-12">Game Book</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>Gametype</th>
                                  <th>EventName</th>
                                  <th>BookA</th>
                                  <th>NationA</th>
                                  <th>BookB</th>
                                  <th>NationB</th>
                                  <th>BookC</th>
                                  <th>NationC</th>
                                  <th>NoOfBet</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.gameBookUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      <td>{user.gameType}</td>
                                      <td>{user.eventName}</td>
                                      <td>{user.bookA}</td>
                                      <td>{user.nationA}</td>
                                      <td>{user.bookB}</td>
                                      <td>{user.nationB}</td>
                                      <td>{user.bookC}</td>
                                      <td>{user.nationC}</td>
                                      <td>{user.noOrBet}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">User Details</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>UserName</th>
                                  <th>Exposer</th>
                                  <th>Creditref</th>
                                  <th>ExpoLimits</th>
                                  <th>General</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.userName
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.exposure
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.creditReference
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.exposureLimit
                                    }
                                  </td>{" "}
                                  <td>
                                    {
                                      userDetails?.accountDataUserDetailsDTO
                                        ?.balance
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 m-t-10">
                            <h4 className="m-b-10 col-md-12">Fancy Book</h4>
                            <table className="table table-striped table-dark">
                              <thead>
                                <tr>
                                  <th>EventName</th>
                                  <th>Book</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userDetails?.fancyBookUserDetailsDTOS?.map(
                                  (user) => (
                                    <tr>
                                      <td>{user.eventName}</td>
                                      <td>{user.book}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <nav class="navbar navbar-expand-md btco-hover-menu">
            <div class="collapse navbar-collapse">
              <ul class="list-unstyled navbar-nav">
                <li class="nav-item">
                  <a href="/admin/manage-client/active-list">List of clients</a>
                </li>

                <li class="nav-item">
                  <a href="/admin/market-analysis">Market Analysis</a>
                </li>
                <li class="nav-item dropdown newlacunch-menu">
                  <a href="javascript:void(0);">
                    <span>
                      Live Market <ChevronDownIcon />
                    </span>
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/race20">
                        <span>Race 20-20</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/queen">
                        <span>Queen</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/baccarat/listing"
                      >
                        <span>Baccarat</span>
                      </a>
                    </li>
                    <li class="newlacunch-menu">
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/sports-casino"
                      >
                        <span>Sports Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/threecards"
                      >
                        <span>3 Cards Judgement</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/card32">
                        <span>32 Cards Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/teenpatti"
                      >
                        <span>Live TeenPatti</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/poker">
                        <span>Live Poker</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/andarbahar"
                      >
                        <span>Andar Bahar</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/lucky7">
                        <span>Lucky 7</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/dt">
                        <span>Dragon Tiger</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/bollywood-casino"
                      >
                        <span>Bollywood Casino</span>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="/admin/live-market/cmeter">
                        <span>Casino Meter</span>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/admin/live-market/war">
                        <span>Casino War</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/admin/live-market/instant_worli/list1"
                      >
                        <span>Worli</span>
                      </a>
                    </li>
                  </ul>
                </li>

                <li class="nav-item dropdown">
                  <a href="javascript:void(0);">
                    Reports <i class="fa fa-caret-down"></i>
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        href="/admin/reports/current-bet"
                        class="dropdown-item"
                      >
                        Current Bets
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/account-statement"
                        class="dropdown-item"
                      >
                        Account's Statement
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/bet-history"
                        class="dropdown-item"
                      >
                        Bet History
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/general-report"
                        class="dropdown-item"
                      >
                        General Report
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/game-report"
                        class="dropdown-item"
                      >
                        Game Report
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/profit-loss"
                        class="dropdown-item"
                      >
                        Profit And Loss
                      </a>
                    </li>
                    <li>
                      <a
                        href="/admin/reports/casino-results"
                        class="dropdown-item"
                      >
                        Casino Result Report
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul class="user-search list-unstyled">
                <li class="username btco-hover-menu">
                  <span>
                    {userName} <ChevronDownIcon />
                  </span>
                  <ul>
                    <li>
                      <a href="/admin/change-password" class="dropdown-item">
                        Change password
                      </a>
                    </li>
                    <li>
                      <a href="/admin/security" class="dropdown-item">
                        Security Auth
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          dispatch({
                            type: "accountData/logOut",
                            payload: {},
                          });
                          window.localStorage.clear();
                          window.location.href = "/";
                        }}
                        class="dropdown-item"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="search">
                  <AsyncSelect
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "none",
                        boxShadow: "none",
                        height: "30px",
                        width: "100px",
                      }),
                      input: (base) => ({
                        ...base,
                        height: "30px",
                        width: "100px",
                      }),

                      indicatorSeparator: (base) => ({
                        ...base,
                        display: "none",
                      }),
                      dropdownIndicator: (base) => ({
                        ...base,
                        display: "none",
                      }),
                      //incraese the width and don't make it overflow
                      menu: (base) => ({
                        ...base,
                        width: "100px",
                        overflow: "hidden",
                        color: "black",
                      }),
                    }}
                    cacheOptions
                    defaultOptions
                    loadOptions={searchClient}
                    onChange={(e) => getUserDetail(e.value)}
                    type="text"
                    name="search-account_details"
                    id="search-account_details"
                    placeholder="All Client"
                    class="ui-autocomplete-input"
                    autocomplete="off"
                  />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
{
  /*    <header>
      <Box className="header_wrapper">
        <Container className="container_fluid">
          <a className="logo">
            <img src={require("../assets/img/logo.png")} />
          </a>
          <button
            className="side_menu_button"
            onClick={onOpen}
            bg="$themeColor"
            ref={drawerButtonRef}
          >
            <HamburgerIcon />
          </button>
          <div className="navbar">
            <Link to="/admin/manage-client/list">
              <Button
                bg="$themeColor"
                _hover={{ bg: "#0f0f0f" }}
                _active={{ bg: "#0f0f0f" }}
              >
                <Text fontSize="14px">List of Clients</Text>
              </Button>
            </Link>
            <Button
              bg="$themeColor"
              _hover={{ bg: "#0f0f0f" }}
              _active={{ bg: "#0f0f0f" }}
            >
              <Text fontSize="14px">Market Analysis</Text>
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                variant="solid"
                bg="$themeColor"
                p="10px"
                _hover={{ bg: "#0f0f0f" }}
                _active={{ bg: "#0f0f0f" }}
              >
                <Text fontSize="14px">Live Market</Text>
              </MenuButton>
              <MenuList bg="$themeColor">
                <MenuItem icon={<AddIcon />} command="⌘T">
                  New Tab
                </MenuItem>
                <MenuItem icon={<ExternalLinkIcon />} command="⌘N">
                  New Window
                </MenuItem>
                <MenuItem icon={<RepeatIcon />} command="⌘⇧N">
                  Open Closed Tab
                </MenuItem>
                <MenuItem icon={<EditIcon />} command="⌘O">
                  Open File...
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                variant="solid"
                bg="$themeColor"
                p="10px"
                _hover={{ bg: "#0f0f0f" }}
                _active={{ bg: "#0f0f0f" }}
              >
                <Text fontSize="14px">Reports</Text>
              </MenuButton>
              <MenuList bg="$themeColor">
                <MenuItem icon={<AddIcon />} command="⌘T">
                  New Tab
                </MenuItem>
                <MenuItem icon={<ExternalLinkIcon />} command="⌘N">
                  New Window
                </MenuItem>
                <MenuItem icon={<RepeatIcon />} command="⌘⇧N">
                  Open Closed Tab
                </MenuItem>
                <MenuItem icon={<EditIcon />} command="⌘O">
                  Open File...
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </Container>
        <Menu>
          <MenuButton
            bg="#0088ccE6"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {userName}
          </MenuButton>
          <MenuList bg="#0088ccE6">
            <MenuItem bg="#0088ccE6">Change Password</MenuItem>
            <MenuItem
              onClick={() => {
                dispatch({
                  type: "accountData/logOut",
                  payload: {},
                });
                window.localStorage.clear();
                window.location.href = "/";
              }}
              bg="#0088ccE6"
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </header>  */
}
