import { Grid, GridItem, Switch, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomInput from "../../../components/UI/CustomInput";
import { ChangeClientStatusAPI } from "../../../service/UserService";

export default function Status({ payload, setPayload }) {
  return (
    <Grid gap={1} fontSize="sm" templateColumns="repeat(3,1fr)">
      <GridItem>
        <Text>{payload?.user?.username}</Text>
      </GridItem>
      <GridItem>
        <Text fontSize="sm">User Active</Text>
        <Switch
          name="isActive"
          defaultChecked={payload?.user?.isActive}
          onChange={(e) =>
            setPayload({
              ...payload,
              isActive: e.target.checked,
            })
          }
        />
      </GridItem>
      <GridItem>
        <Text fontSize="sm">Bet Active</Text>
        <Switch
          name="isBetActive"
          defaultChecked={!payload?.user?.isBetLocked}
          onChange={(e) =>
            setPayload({
              ...payload,
              isBetActive: !e.target.checked,
            })
          }
        />
      </GridItem>
      <GridItem>
        <Text>Transaction Password</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          type="password"
          value={payload?.transactionPassword}
          name="transactionPassword"
          onChange={(e) =>
            setPayload({ ...payload, transactionPassword: e.target.value })
          }
        />
      </GridItem>
    </Grid>
  );
}
