import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomSelect from "../../components/UI/CustomSelect";
import {
  addNewClientAPI,
  getGlobalSettingsAPI,
  getUserDataAPI,
} from "../../service/UserService";
import { Encrypt, ROLE_MAPPING } from "../../utils/constants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { checkIfUserNameExistsAPI } from "../../service/AuthService";
import { useLocation } from "react-router-dom";
export default function AddClient() {
  const [clientData, setClientData] = useState({});
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const role = useSelector((state) => state.account.userData.userType);
  const [error, setError] = useState(false);
  const {
    state: { id },
  } = useLocation();
  const checkIfUserNameExists = async (username) => {
    const { message } = await checkIfUserNameExistsAPI(username);
    return message;
  };
  const setData = (e) => {
    if (e.target.name.endsWith("DownLine")) {
      let currentKey = e.target.name.split("DownLine")[0];
      if (
        e.target.value > clientData.partnershipAndCommission[currentKey] ||
        e.target.value < 0
      ) {
        return;
      }
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
        [currentKey]:
          clientData.partnershipAndCommission[currentKey] - e.target.value > 0
            ? clientData.partnershipAndCommission[currentKey] - e.target.value
            : 0,
      });
    } else {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    }
    onBlurValidation(e);
  };
  const onBlurValidation = async (e) => {
    let numberCheckRegex = /^[0-9]*$/;
    switch (e.target.name) {
      case "username":
        if (e.target.value.length > 20) {
          // toast.error("Username cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Username cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.includes(" ")) {
          // toast.error("Username cannot contain spaces");
          setError({
            ...error,
            [e.target.name]: "Username cannot contain spaces",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.length < 3) {
          // toast.error("Username must be at least 3 characters long");
          setError({
            ...error,
            [e.target.name]: "Username must be at least 3 characters long",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.length > 3) {
          try {
            let check = await checkIfUserNameExists(e.target.value);

            setError({
              ...error,
              [e.target.name]: {
                message: check,
                isError: check == "Username already exists",
              },
            });
          } catch (err) {
            setError({
              ...error,
              [e.target.name]: {
                message: "Username is already taken",
                isError: true,
              },
            });
          }
        }

        break;

      case "creditReference":
        if (e.target.value.length > 10) {
          //toast.error("Credit reference cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]:
              "Credit reference cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          //toast.error("Credit reference must be a number");
          setError({
            ...error,
            [e.target.name]: "Credit reference must be a number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }

        break;
      case "exposureLimit":
        if (e.target.value.length > 10) {
          // toast.error("Exposure  cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Exposure  cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          // toast.error("Exposure must be a number");
          setError({
            ...error,
            [e.target.name]: "Exposure must be a number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }

        break;
      case "city":
        if (e.target.value.length > 20) {
          //  toast.error("City cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "City cannot be greater than 20 characters",
          });
        } else if (e.target.value.includes(" ")) {
          //toast.error("City cannot contain spaces");
          setError({
            ...error,
            [e.target.name]: "City cannot contain spaces",
          });
        } else if (parseInt(e.target.value)) {
          //toast.error("City cannot contain numbers");
          setError({
            ...error,
            [e.target.name]: "City cannot contain numbers",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "password":
        if (e.target.value.length < 8) {
          // toast.error("Password must be at least 3 characters long");
          setError({
            ...error,
            [e.target.name]: "Password must be at least 8 characters long",
          });
        } else if (
          !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(e.target.value)
        ) {
          setError({
            ...error,
            [e.target.name]:
              "Password must contain at least one uppercase letter, one lowercase letter and one number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "confirmPassword":
        if (e.target.value !== payload.password) {
          // toast.error("Password and Confirm Password must be same");
          setError({
            ...error,
            [e.target.name]: "Password and Confirm Password must be same",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "fullName":
        if (e.target.value.length > 20) {
          // toast.error("Full name cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Full name cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
      default:
        setError(false);
        break;
    }
  };
  const getGlobalSetting = async () => {
    const { response } = await getGlobalSettingsAPI();
    setGlobalSetting(response);
    setPayload({
      ...payload,
      ...response,
    });
  };
  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    if (response.userType == "CLIENT") {
      setIsAdmin(false);
    }
    setClientData(response);
  };

  const handleSubmit = async () => {
    let filteredPayload = payload;
    Object.keys(filteredPayload).forEach((key) => {
      if (key.endsWith("DownLine") || key == "") {
        delete filteredPayload[key];
      }
    });
    if (!filteredPayload.creditReference) {
      toast.error("credit referrence is required");
      return;
    }
    if (!filteredPayload.userType) {
      toast.error("User type is required");
      return;
    }
    if (
      !filteredPayload.exposureLimit &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit is required");
      return;
    }

    if (!filteredPayload.fullName) {
      toast.error("Full name is required");
      return;
    }
    //number check for exposure limit and credit limit
    if (
      isNaN(
        filteredPayload.exposureLimit || filteredPayload.exposureLimit < 0
      ) &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit must be a number");
      return;
    }

    if (
      isNaN(filteredPayload.creditReference) ||
      filteredPayload.creditReference < 0
    ) {
      toast.error("Credit reference must be a number");
      return;
    }

    if (filteredPayload.userType == "CLIENT") {
      filteredPayload.minBetCricket = payload.minBetCricket;
      filteredPayload.minBetFootball = payload.minBetFootball;
      filteredPayload.minBetTennis = payload.minBetTennis;
      filteredPayload.maxBetCricket = payload.maxBetCricket;
      filteredPayload.maxBetFootball = payload.maxBetFootball;
      filteredPayload.maxBetTennis = payload.maxBetTennis;
      filteredPayload.delayCricket = payload.delayCricketOdds;
      filteredPayload.delayFootball = payload.delayFootballOdds;
      filteredPayload.delayTennis = payload.delayTennisOdds;
    }
    if (filteredPayload.password !== filteredPayload.confirmPassword) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error("Password and Confirm Password must be same");
      return;
    }

    if (filteredPayload.password.length < 3) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error("Password must be at least 3 characters long");
      return;
    }

    filteredPayload.commissionInCricket =
      clientData?.partnershipAndCommission?.commissionInCricket -
      payload.commissionInCricket;
    filteredPayload.commissionInFootball =
      clientData?.partnershipAndCommission?.commissionInFootball -
      payload.commissionInFootball;
    filteredPayload.commissionInTennis =
      clientData?.partnershipAndCommission?.commissionInTennis -
      payload.commissionInTennis;
    filteredPayload.commissionInCasino =
      clientData?.partnershipAndCommission?.commissionInCasino -
      payload.commissionInCasino;
    filteredPayload.partnershipInCricket =
      clientData?.partnershipAndCommission?.partnershipInCricket -
      payload.partnershipInCricket;
    filteredPayload.partnershipInFootball =
      clientData?.partnershipAndCommission?.partnershipInFootball -
      payload.partnershipInFootball;
    filteredPayload.partnershipInTennis =
      clientData?.partnershipAndCommission?.partnershipInTennis -
      payload.partnershipInTennis;

    filteredPayload.parentUserId = id;

    delete filteredPayload["confirmPassword"];
    filteredPayload.password = Encrypt(payload.password);
    try {
      const { response, code, error, status } = await addNewClientAPI(
        filteredPayload
      );
      if (code === 200) {
        toast.success(response?.status || "User Added Successfully");
        setPayload({});
        setTimeout(() => {
          window.location.href = "/admin/manage-client/list";
        }, 500);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error(
          error.description || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };
  useEffect(() => {
    //check if client name is already taken
    if (payload.username) {
      if (clientData.username == payload.username) {
        toast.error("Username is already taken");
        setPayload({
          ...payload,
          username: "",
        });
      }
    }
  }, [payload]);
  useEffect(() => {
    getGlobalSetting();
    getUserInfo();
  }, []);
  return (
    <Box p="5" bg="#F9F9F9">
      <Text fontSize="xl">Add Account</Text>
      <Grid mt="5" rowGap={2} columnGap={6} templateColumns={"repeat(2,1fr)"}>
        <GridItem>
          <Box p="5px 10px" className="title_bar">
            Personal Details
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
            templateRows="repeat(3,1fr)"
            columnGap={10}
            rowGap={2}
            p="2"
          >
            <GridItem>
              <CustomInput
                name="username"
                onChange={setData}
                label="Client Name:"
                value={payload.username}
                error={error.username}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="password"
                onChange={setData}
                label="Password:"
                type="password"
                value={payload.password}
                error={error.password}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="confirmPassword"
                onChange={setData}
                label="Confirm Password:"
                type="password"
                value={payload.confirmPassword}
                error={error.confirmPassword}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="fullName"
                onChange={setData}
                label="Full Name:"
                value={payload.fullName}
                error={error.fullName}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="city"
                onChange={setData}
                label="City:"
                value={payload.city}
                error={error.city}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="contact"
                type="number"
                onChange={setData}
                label="Phone:"
                value={payload.contact}
                error={error.contact}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Box p="5px 10px" className="title_bar">
            Account Details
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1,1fr)", lg: "repeat(2,1fr)" }}
            templateRows="repeat(3,1fr)"
            columnGap={10}
            rowGap={2}
            p="2"
          >
            <GridItem>
              <CustomSelect
                onChange={setData}
                name="userType"
                label="Account Type:"
                options={ROLE_MAPPING[role]}
                value={payload.userType}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="creditReference"
                onChange={setData}
                type="number"
                label="Credit Reference:"
              />
            </GridItem>
            {payload?.userType == "CLIENT" && (
              <GridItem>
                <CustomInput
                  name="exposureLimit"
                  onChange={setData}
                  type="number"
                  label="Exposure Limit:"
                />
              </GridItem>
            )}
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Commission Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Up Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInCricketDefault"
                value={
                  clientData?.partnershipAndCommission?.commissionInCricket
                }
                disabled
                onChange={setData}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInFootballDefault"
                disabled
                onChange={setData}
                value={
                  clientData?.partnershipAndCommission?.commissionInFootball
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                name="commissionInTennisDefault"
                onChange={setData}
                value={clientData?.partnershipAndCommission?.commissionInTennis}
              />
            </GridItem>
            <GridItem>
              <Text>Down Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                type="number"
                max={
                  clientData?.partnershipAndCommission
                    ?.commissionInCricketDownLine
                }
                name="commissionInCricketDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInCricketDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInFootballDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInFootballDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInTennisDownLine"
                onChange={setData}
                disabled={payload.userType === "CLIENT"}
                value={payload?.commissionInTennisDownLine}
              />
            </GridItem>
            <GridItem>
              <Text>Our</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                name="commissionInCricket"
                disabled
                onChange={setData}
                value={payload.commissionInCricket}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                name="commissionInFootball"
                onChange={setData}
                value={payload.commissionInFootball}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                disabled
                name="commissionInTennis"
                value={payload.commissionInTennis}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Partnership Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Up Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInCricketDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInCricket
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInFootballDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInFootball
                }
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                name="partnershipInTennisDefault"
                value={
                  clientData?.partnershipAndCommission?.partnershipInTennis
                }
              />
            </GridItem>
            <GridItem>
              <Text>Down Line</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInCricketDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInCricketDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInFootballDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInFootballDownLine}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                name="partnershipInTennisDownLine"
                disabled={payload.userType === "CLIENT"}
                value={payload?.partnershipInTennisDownLine}
              />
            </GridItem>
            <GridItem>
              <Text>Our</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInCricket}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInFootball}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                disabled
                onChange={setData}
                value={payload?.partnershipInTennis}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p="5px 10px" className="title_bar">
            Bet Limit Setting:
          </Box>
          <Grid
            templateColumns="repeat(4,1fr)"
            templateRows="repeat(4,1fr)"
            rowGap={2}
            columnGap={3}
            p="2"
          >
            <GridItem colStart={2}>
              <Text>Cricket</Text>
            </GridItem>
            <GridItem>
              <Text>Soccer</Text>
            </GridItem>
            <GridItem>
              <Text>Tennis</Text>
            </GridItem>
            <GridItem>
              <Text>Minimum</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetCricket}
                name="minBetCricket"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetFootball}
                name="minBetFootball"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.minBetTennis}
                name="minBetTennis"
              />
            </GridItem>
            <GridItem>
              <Text>Maximum</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetCricket}
                name="maxBetCricket"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetFootball}
                name="maxBetFootball"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.maxBetTennis}
                name="maxBetTennis"
              />
            </GridItem>
            <GridItem>
              <Text>Delay</Text>
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayCricketOdds}
                name="delayCricketOdds"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayFootballOdds}
                name="delayFootballOdds"
              />
            </GridItem>
            <GridItem>
              <CustomInput
                onChange={setData}
                value={payload.delayTennisOdds}
                name="delayTennisOdds"
              />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem colStart={2}>
          <Box display="flex" mr="3" justifyContent="flex-end">
            <CustomInput
              name="transactionPassword"
              onChange={setData}
              w="100%"
              label="Transaction Password:"
              placeholder="Transaction Password"
              type="password"
            />
          </Box>
        </GridItem>
        <GridItem colStart={2}>
          <button
            onClick={handleSubmit}
            style={{
              float: "right",
            }}
            disabled={
              !payload.transactionPassword ||
              payload.transactionPassword.length < 6 ||
              !parseInt(payload.transactionPassword)
            }
            className="btn"
          >
            <Text fontSize="sm">Create Account</Text>
          </button>
        </GridItem>
      </Grid>
    </Box>
  );
}
