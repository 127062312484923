import { Switch, Td, Text, Toast, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomDataModal from "../../../components/UI/CustomDataModal";
import CustomTable from "../../../components/UI/Table";

export default function Ladder({ isOpen, onClose, data }) {
  let sortedData = [...data];
  sortedData.sort((a, b) => {
    return b.exposure - a.exposure;
  });
  const [rows, setRows] = React.useState(sortedData);

  const columns = [
    {
      name: "Runs",
      id: "selectionName",
    },
    {
      name: "Exposure",
      id: "exposure",
    },
  ];

  return (
    <CustomDataModal header={"Exposure"} onClose={onClose} isOpen={isOpen}>
      <CustomTable columns={columns} rows={rows} showPagination={false} />
    </CustomDataModal>
  );
}
