import { Route, Routes, useLocation } from "react-router-dom";
import PrivateOutlet from "./layout/PrivateOutlet";
import PublicOutlet from "./layout/PublicOutlet";
import AddClient from "./pages/Client/Add";
import ListClient from "./pages/Client/List";
import Game from "./pages/Game/Game";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import store, { saveState } from "./redux/store";
import ChangePassword from "./pages/ChangePassword";
import CustomModal from "./components/UI/CustomModal";
import { createSocket } from "./utils/constants";
import AccountStatement from "./pages/Reports/AccountStatement";
import GeneralReport from "./pages/Reports/GeneralReport";
import ProfitLoss from "./pages/Reports/ProfitLoss";
import CasinoResult from "./pages/Reports/CasinoResult";
import CurrentBets from "./pages/Reports/CurrentBets";
import GameReport from "./pages/Reports/GameReport";
import MyProfitLoss from "./pages/MyProfitLoss";
import ActivityLog from "./pages/Client/Activity";
import BetHistory from "./pages/Reports/BetHistory";
import SecurityAuth from "./pages/securityAuth/SecurityAuth";
import Otp from "./pages/Otp";
import ChangePasswordNew from "./pages/ChangePasswordNew";
import TransactionPassword from "./pages/TransactionPassword";

export default function App() {
  const userData = useSelector((state) => state.account.userData);
  const ui = useSelector((state) => state.account.ui);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);
  return (
    <>
      {userData.token && !userData.resetRequired ? (
        <PrivateOutlet>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin/manage-client/list" element={<ListClient />} />
            <Route
              path="/admin/manage-client/list/:username/:id"
              element={<ListClient />}
            />
            <Route
              path="/admin/manage-client/active-list"
              element={<ListClient activeuser={true} />}
            />
            <Route path="/admin/manage-client/add" element={<AddClient />} />
            <Route path="/admin/game/:game/:id" element={<Game />} />
            <Route
              path="/admin/reports/account-statement"
              element={<AccountStatement />}
            />
            <Route
              path="/admin/reports/general-report"
              element={<GeneralReport />}
            />
            <Route path="/admin/reports/profit-loss" element={<ProfitLoss />} />
            <Route
              path="/admin/reports/casino-results"
              element={<CasinoResult />}
            />
            <Route
              path="/admin/reports/current-bet"
              element={<CurrentBets />}
            />
            <Route path="/tp" element={<TransactionPassword />} />
            <Route path="/admin/reports/bet-history" element={<BetHistory />} />
            <Route path="/admin/reports/game-report" element={<GameReport />} />
            <Route
              path="/admin/change-password"
              element={<ChangePassword internal={true} />}
            />
            <Route path="/admin/market-analysis" element={<MyProfitLoss />} />
            <Route
              path="/admin/activity-log/:username"
              element={<ActivityLog />}
            />
            <Route path="/admin/security" element={<SecurityAuth />} />
          </Routes>
        </PrivateOutlet>
      ) : userData.token && userData.resetRequired ? (
        <PublicOutlet>
          <Routes>
            <Route path="/tp" element={<TransactionPassword />} />
            <Route path="*" element={<ChangePasswordNew internal={true} />} />
            <Route path="/" element={<ChangePasswordNew internal={true} />} />
          </Routes>
          <CustomModal header={ui?.modalHeader} isOpen={ui?.modalOpen} />
        </PublicOutlet>
      ) : (
        <PublicOutlet>
          <Routes>
            <Route>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route path="/otp" element={<Otp />} />
            </Route>
          </Routes>
        </PublicOutlet>
      )}
      <CustomModal header={ui?.modalHeader} isOpen={ui?.modalOpen} />
    </>
  );
}


